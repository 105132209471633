<template>
  <BasicLayout>
    <template v-slot:content>
      <h1 class="title">{{ $t('signup.label') }}</h1>

      <b-message v-if="errors.length" type="is-danger">
        <div v-for="error in errors" :key="error.key">
          <div v-if="error.key == 'InvalidRecoverPasswordCode'">
            {{ $t('signup.resetCode') }}
          </div>
          <div v-else-if="error.key == 'MaxAttempts'">
            {{ $t('signup.maxAttemptsReached') }}<br />{{ $t('try.later') }}
          </div>
          <div v-else-if="error.key == 'AccountAlreadyActivated'">
            {{ $t('signup.emailregistered') }}<br /><br />
            <small
              >{{ $t('signup.difficulties') }}
              <router-link to="/recover/password">{{ $t('signup.recoverPassword') }}</router-link>
              {{ $t('signup.contactSupport') }}</small
            >
          </div>
          <div v-else-if="error.key == 'MinStringLength'">
            {{ $t('signup.pwdMinChars') }}
          </div>
          <div v-else-if="error.key == 'ThreeLettersRequired'">
            {{ $t('signup.pwdMinLetters') }}
          </div>
          <div v-else>
            <p>{{ $t('signup.registrationNotPossible') }}</p>
            <p>{{ error.message }}</p>
          </div>
        </div>
      </b-message>

      <div v-if="registerDone">
        <div v-if="registerNeedsActivation">
          <b-message has-icon type="is-success">
            <h3>{{ $t('signup.registrationSuccessful') }}</h3>
          </b-message>
          <div>
            <p>
              {{ $t('signup.validateAccount') }}
            </p>
            <p class="has-text-small has-margin-top">
              {{ $t('signup.checkSpam') }}
            </p>
          </div>
        </div>
        <div v-else>
          <b-message has-icon type="is-success">
            <p class="is-size-3">{{ $t('signup.registrationSuccessful') }}</p>
          </b-message>
          <div>
            <p>
              {{ $t('signup.finishSignin') }}
            </p>
          </div>
        </div>
        <p class="has-margin-top-large">
          <b-button expanded type="is-primary" outlined @click="$router.push('/login')">{{
            $t('login.openApplication')
          }}</b-button>
        </p>
      </div>

      <form v-else class="login-form" @keypress.enter="doRegister" @submit.prevent="doRegister">
        <b-field
          :label="$t('fullName')"
          label-for="full_name"
          :type="{ 'is-danger': $v.fullName.$error }"
          :message="$v.fullName.$error ? $t('required') : ''"
        >
          <b-input v-model="fullName" placeholder="Indique o seu nome completo" type="input"></b-input>
        </b-field>

        <b-field
          label="Email"
          label-for="email"
          :type="{ 'is-danger': $v.email.$error }"
          :message="$v.email.$error ? $t('invalidEmail') : ''"
        >
          <b-input
            v-model="email"
            placeholder="Indique um endereço de email válido"
            type="email"
            @input="checkEmail"
          ></b-input>
        </b-field>

        <b-message v-if="institutionalEmail">
          {{ $t('signup.institutionalAccounts') }}<br /><br />
          {{ $t('signup.institutionalSignin') }} {{ email }} {{ $t('signup.institutionalSigninPwd') }}
        </b-message>

        <b-message
          v-if="isExternalInstitutionalEmail"
          type="is-warning"
          v-html="$t('signup.institutionalNotAvailable')"
        ></b-message>

        <b-field
          v-if="!institutionalEmail"
          :label="$t('password')"
          label-for="password"
          :type="{ 'is-danger': $v.password.$error }"
          :message="$v.password.$error ? $t('signup.pwdValid') : ''"
        >
          <b-input v-model="password" password-reveal type="password" name="password" />
        </b-field>
        <p v-if="!institutionalEmail" class="instructions">
          {{ $t('signup.pwdSafe') }}
        </p>

        <b-field
          v-if="!institutionalEmail"
          :label="$t('signup.pwdConfirm')"
          label-for="confirm-password"
          :type="{ 'is-danger': $v.confirmPassword.$error }"
          :message="$v.confirmPassword.$error ? $t('signup.pwdMatch') : ''"
        >
          <b-input v-model="confirmPassword" password-reveal type="password" name="confirm-password" />
        </b-field>

        <b-field class="has-margin-top-medium" :label="$t('signup.privacyPoliceAcceptance')">
          <div class="has-text-small content">
            Declaro que li e tomei conhecimento da Política de Privacidade e de Proteção de Dados da Universidade de
            Coimbra (UC), disponível na página web e mais declaro que tomei conhecimento, que:
            <ol type="a">
              <li>
                A finalidade da recolha dos meus dados pessoais pela UC, no presente contexto, destina-se à minha
                identificação e credenciação como utilizador habituado a utilizar as funcionalidades da plataforma onde
                decorrerá a tramitação dos procedimentos concursais de recrutamento de trabalhadores da UC;
              </li>
              <li>
                Os dados pessoais solicitados, nome, email e contacto telefónico são os dados considerados essenciais
                para a organização administrativa e técnica da plataforma;
              </li>
              <li>
                O prazo de conservação de dados pessoais é o que estiver fixado por norma legal ou regulamentar;
              </li>
              <li>
                Na qualidade titular dos dados, em conformidade com a Lei, tenho os direitos de acesso e de retificação,
                podendo estes ser exercidos, por escrito, mediante comunicação por correio eletrónico para
                <a href="sgrh@uc.pt">sgrh@uc.pt</a>;
              </li>
              <li>
                O Encarregado de Proteção de Dados da UC pode ser contactado pelo email
                <a href="mailto:epd@uc.pt">epd@uc.pt</a>.
              </li>
            </ol>
          </div>
        </b-field>
        <b-field class="has-margin-top-medium">
          <b-checkbox v-model="privacyAccepted" name="privacy-accepted"
            >Declaro que li e tomei conhecimento da Política de Privacidade</b-checkbox
          >
        </b-field>
        <b-field>
          <b-button
            :disabled="!isRegisterReady"
            expanded
            :loading="loading"
            :type="isRegisterReady ? 'is-primary' : 'is-black'"
            @click="doRegister"
          >
            {{ $t('continue') }}
          </b-button>
        </b-field>
        <b-field class="has-text-centered has-margin-top-medium">
          <b-button type="is-text" size="is-small" @click="$router.push('/login')">{{
            $t('signup.backToSignin')
          }}</b-button>
        </b-field>
      </form>
    </template>
  </BasicLayout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import debounce from 'lodash/debounce'

import BasicLayout from '@/components/layouts/BasicLayout'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BasicLayout,
  },
  // https://css-tricks.com/form-validation-in-under-an-hour-with-vuelidate/
  data() {
    return {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      privacyAccepted: false,
      institutionalEmail: false,
      isExternalInstitutionalEmail: false,
      registerDone: false,
      registerNeedsActivation: false,
      loading: false,
      errors: [],
    }
  },

  computed: {
    isRegisterReady() {
      if (this.privacyAccepted) {
        if (this.institutionalEmail && !this.$v.fullName.$error) {
          return true
        }

        this.$v.$touch()
        if (!this.$v.$invalid) {
          return true
        }
      }
      return false
    },
  },

  // Validações do formulário de registo
  validations: {
    fullName: { required, min: minLength(2) },
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(8) },
    confirmPassword: {
      confirm() {
        if (this.institutionalEmail) {
          return true
        }
        return this.password == this.confirmPassword
      },
    },
    privacyAccepted: { checked: value => value === true },
  },

  created() {
    this.checkEmail = debounce(() => {
      this.institutionalEmail = AuthService.helpers.isInstitutionalEmail(this.email)
      this.isExternalInstitutionalEmail = AuthService.helpers.isExternalInstitutionalEmail(this.email)
    }, 500)
  },

  methods: {
    doRegister() {
      this.loading = true

      this.$v.$touch()

      // Realiza pedido de login apenas se o formulário for validado
      if (this.isRegisterReady) {
        this.errors = []

        AuthService.register(this.email, this.password, this.fullName)
          .then(response => {
            this.registerDone = true
            this.registerNeedsActivation = response.data.required_to_activate
            this.loading = false
          })
          .catch(errorResponse => {
            this.errors = errorResponse.response.data.__errors__
            this.loading = false

            this.$buefy.snackbar.open({
              message: this.$t('signup.error'),
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000,
            })

            // Garante que o utilizador vê o erro
            window.scrollTo(0, 0)
          })
      }
      this.loading = false
    },
  },
}
</script>
